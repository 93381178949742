import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';

import DashboardSide from "../Side";
import {
    DashboardHeaderContainer,
    DashboardHeaderAction,
    DashboardHeaderActionIcon,
    ContentIcon,
    HeaderContent,
    HeaderLinkContain,
    HeaderLink,
    InpuntContainer,
    ButtonContainer,
    CartContent,
    CartIcon,
    CartNumber,
    FormContent
} from "./styled";
import { Icon } from "ui/styled";
import Button from "components/Form/Button";

import { toast } from 'react-toastify';
import Input from "components/Form/Input";
import useWindowSize from "utils/useWindowSize";
import Select from "components/Form/Select";
import { brandOptions } from "utils/options";
import { CoreContext } from "context/CoreContext";
import { Read } from "services/about-brands";
import { normalizeStrapiList } from "utils";

//replace with quantity
const cartNumber = 2

export default function DashboardHeader() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    const [opened, setOpened] = useState(false)
    const windowSize = useWindowSize();
    const [selectedOption, setSelectedOption] = useState('Jim beam');

    const params = new URLSearchParams(window.location.search);
    const search = params.get('search');

    const [form, setForm] = useState(search ? { search } : {})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    const { cart, brands, setBrands } = useContext(CoreContext)

    const total_quantity = useMemo(() => {
        return cart?.reduce((p, c) => p + (parseFloat(c?.quantity) || 1), 0) || 0
    }, [cart])


    const handleChange = (brandName) => {
        const brand = brands?.find(f => f.id === parseInt(brandName))

        const formattedBrandName = brand?.title?.replace(/\s+/g, '-').replace(/’/g, '');

        navigate(`marcas/${formattedBrandName}`)
    };

    const init = async () => {
        const result = await Read()

        const normalResult = normalizeStrapiList(result)
        setBrands(normalResult)
    }

    useEffect(() => { init(); }, [])


    return (
        <>
            <DashboardHeaderContainer>
                <FormContent>
                    <DashboardHeaderAction onClick={() => setOpened(true)}>
                        <DashboardHeaderActionIcon src={`/icons/menu.svg`} alt="menu-icon" />
                    </DashboardHeaderAction>
                    <ContentIcon>
                        <Icon src={'/icons/header-logo.svg'} alt="logo-icom" onClick={() => navigate('')} />
                    </ContentIcon>
                    {
                        windowSize.width < 744 ? null :
                            <>
                                <HeaderContent>
                                    <Button primary nospace small noRadius width={'76px'} onClick={() => navigate('')}>
                                        Home
                                    </Button>

                                    {
                                        windowSize.width < 1180 ? null :
                                            <HeaderLinkContain>
                                                <Select
                                                    placeholder="Nossas marcas"
                                                    options={brands}
                                                    onChange={handleChange}
                                                    width='158px'
                                                    noRadius
                                                    padding='14px 0px 14px 8px'
                                                />
                                            </HeaderLinkContain>
                                    }

                                    {
                                        windowSize.width < 1000 ? null :
                                            <>
                                                <HeaderLinkContain>
                                                    <HeaderLink onClick={() => navigate('produtos')}>Produtos</HeaderLink>
                                                </HeaderLinkContain>
                                                <InpuntContainer>
                                                    <Input noClear placeholder="Buscar" holder lightgrey type="text" search value={formValue('search')} noRadius onChange={e => changeForm(e.target.value, 'search')} onSubmitEditing={() => navigate(`produtos?search=${formValue('search')}`)} />
                                                </InpuntContainer>
                                            </>
                                    }
                                </HeaderContent>
                            </>
                    }
                    <ButtonContainer>
                        {/* {
                            windowSize.width < 744 ? null :
                                <Button nospace width={'77px'} noRadius small info onClick={() => navigate('login')}>Entrar</Button>
                        } */}
                        <CartContent onClick={() => navigate('cart')}>
                            <Icon src={'/icons/cart.svg'} alt="cart-icon" />
                            {!total_quantity ? null : <CartNumber>{total_quantity}</CartNumber>}
                        </CartContent>
                    </ButtonContainer>
                </FormContent >
            </DashboardHeaderContainer>
            <DashboardSide opened={opened} setOpened={setOpened} />


            {windowSize.width < 1000 ? (
                <InpuntContainer>
                    <Input white noClear placeholder="Buscar" holder type="text" search value={formValue('search')} noRadius onChange={e => changeForm(e.target.value, 'search')} onSubmitEditing={() => navigate(`produtos?search=${formValue('search')}`)} />
                </InpuntContainer>
            ) : null}
        </>
    );
}