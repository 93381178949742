import styled from 'styled-components'
import ReactLoading from 'react-loading';
import Lottie from 'react-lottie';

export const hexToRgb = (hex) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
    }
    return `255,255,255`
}

export const Touch = styled.div.attrs({
})`         
    cursor: pointer;
    &:hover{
        box-shadow: 0px 1px 3px ${props => props.theme.palette.colors.shadow};
    }
`;

export const Load = styled(ReactLoading).attrs(p => ({
    type: 'spin',
    color: p?.black ? p.theme.palette.colors.black : p.theme.palette.colors.white,
    height: 20,
    width: 20
}))`          
`;

export const Animation = styled(Lottie).attrs(props => ({
    options: {
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
        animationData: props.animationData
    },
    width: props.width ? props.width : 320
}))`        
    max-width: 100%;
`;

export const EmptyMessage = styled.div.attrs({
})`         
    padding: 32px; 
    text-align: center;
    font-size: 14px;
    color: ${props => props.theme.palette.colors.black};
`;

export const LoadCenter = styled.div.attrs({
})`         
    width: 20px;
    margin: 32px auto; 
    ${p => p.big ? `margin: 20vh auto;` : ``}
    display: flex;
    justify-content: center;
`;

export const DecoratedScroll = styled.div.attrs({
})`

    overflow: auto; 

    ::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.palette.secondary.main};
        outline: 0 solid ${props => props.theme.palette.secondary.main};
    }

    ::-webkit-scrollbar {
        width: 1px;
        height: .2rem;
    }

    ::-webkit-scrollbar-track {  
    }
`;

export const Icon = styled.img.attrs((props) => ({
}))` 
    margin: 0 2px;      
    ${p => !!p.onClick ? `  
            cursor: pointer;
        ` : ``
    }
    ${p => p.width ? `
        width: ${p.width};
        ` : ``}
`;
export const Img = styled.img.attrs({
    width: '100%'
})` 
    max-width: ${props => props.big ? '' : '235.2px'};
    mix-blend-mode: multiply; 
`;
export const ContentIcon = styled.div.attrs({
})`         
    display: flex;
    width: 100%;
    ${props => props.center ? `justify-content: center;`
        : ` ${props.start ? ` justify-content: flex-start;`
            : ` ${props.end ? ` justify-content: flex-end;`
                : `${props.between ? ` justify-content: space-between;
                ` : ``
                }`
            }`
        }`
    }`;

export const Title = styled.div.attrs({
})` 
  margin-bottom: ${props => props.noSpace ? `0px` : `24px`};
  font-family: DM Serif Display;
  font-size: 32px;
  font-weight: 400;
  line-height: 56px;
  text-align: left;
  color: ${props => props.white ? props.theme.palette.white.main : ''};

  ${props => props.center ? `text-align: center;` : ``}
  ${props => props.top ? `margin-top: 24px;` : ``}
  @media(max-width: 767px){
    font-size: 26px;
  } 

`;

export const Content = styled.div.attrs({
})` 
    ${props => props.width ? `width: ${props.width};` : ``}
    ${props => props.border ? `
        border-top: 1px solid ${props.theme.palette.gray.gray100};
        padding-top: 5px;
        ` : ``}
    ${p => p.flex ? `
        display: flex;
        align-items: center;
        gap: 8px;    
    ` : ``}
`;

export const FormContent = styled.div.attrs({
})` 
    max-width: 1512px;  
    padding: 32px 120px;
    margin: 0 auto;
    @media (max-width: 600px) {
        padding: 16px;
    }

    ${p => p.noBottom ? `padding: 32px 120px 0 120px;` : ``}
    ${p => p.noPadding ? `padding: 0 120px;` : ``}

`;

export const MeTitle = styled.div.attrs({
})`
    font-family: DM Serif Display;
    font-size: 32px;
    font-weight: 400;
    line-height: 56px;
    text-align: left;
    @media (max-width: 600px) {
        display: flex;
        justify-content: space-between;
    }
`;

export const DetailsTitle = styled.div.attrs({
})`

  font-family: DM Serif Display;
    font-size: 24px;
    font-weight: 400;
    line-height: 48px;
    text-align: left;

  padding-bottom: 8px;
  ${props => props.border ? `
    border-bottom: 1px solid ${props.theme.palette.colors.grey};
    margin-top: 5px;
    ` : ``}
    @media (max-width: 600px) {
        padding-left: 16px;
    }
`;

export const MeContent = styled.div.attrs({
})`
    background-color: ${props => props.theme.palette.white.main};
    padding: 16px;
    max-width: 960px;
    flex: 1;

`;

export const ButtonContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: center;
    ${props => props.space ? `gap: 24px` : ``}
`;


export const BrandContainer = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette[props.brand] ? props.theme.palette[props.brand].secondary : props.theme.palette["commom"].secondary};
  padding-bottom: ${p => p?.noBottom ? '0px' : '48px'};
  position: relative;
`;

export const BrandTitle = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 48px;
  font-weight: 400;
  line-height: 80px;
  text-align: ${p => p.center ? 'center' : 'left'};
  color: ${props => props.theme.palette[props?.brand].contrastTitle};

  ${p => p.max ? `max-width: 600px;` : ``}
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 48px;
  }
`;

export const FormSpace = styled.div.attrs({
})`
    margin-bottom: 32px;
    ${p => p.large ? `margin-bottom: 64px;` : ``}
`;