import React from 'react'
import { BrandContainer, BrandTitle, FormContent, FormSpace } from 'ui/styled'
import { HistoryContainer, Image, PillarsCardContainer, PillarsContent, PillarsImage, PillarsImageContainer, SamuelsFamilyImage, Subtitle, TitleContainer } from './styled'
import PillarsCard from 'components/Cards/Pillars';
import MacallanCard from 'components/Cards/Macallan';



export default function TheMacallan({ brand, productDetails }) {
  const pillarsInfo = [
    {
      title: 'The Macallan Estate',
      description: 'The Macallan está localizada dentro de uma verdadeira fazenda produtiva, nas margens do rio Spey. Nessa propriedade com paisagem espetacular, plantamos a cevada, produzimos e envelhecemos nossos whiskies sob o clima das Highlands. A sede dessa propriedade é um casarão de 1700, que é o símbolo da destilaria.Ele guarda toda a história e segredos de The Macallan.',
      image: '/images/macallan1.jpg'
    },
    {
      title: 'Alambiques curiosamente pequenos',
      description: 'Os destiladores tem formato único e são os menores da Escócia. Isso garante que o new make, ou álcool recém destilado, seja mais intenso em aromas e sabores. Apenas 16% do total destilado nos nossos destiladores é levado aos barris para envelhecer, o menor índice do mercado.Isso garante maior pureza e qualidade para uma experiência sem igual.',
      image: '/images/macallan2.jpg',
      reverse: true
    },
    {
      title: 'Barris de carvalho nobre',
      description: 'A alma de um whisky de deve à madeira onde ele envelheceu, e quanto melhor a qualidade do barril, mais complexo e saboroso será o whisky.The Macallan investe 12x mais nos seus barris que a média do mercado.Utilizam árvores centenárias de manejo sustentável, sendo o padrão médio na Escócia de 40 anos. The Macallan é a única destilaria a ter um Master of Wood, um especialista em madeiras que supervisiona todos os processos de produção dos barris para garantir a mais alta qualidade.',
      image: '/images/macallan3.jpg',
    },
    {
      title: 'Barris com uso prévio em Jerez',
      description: 'Os barris utilizados possuem um uso prévio  com vinho Jerez de primeira qualidade, responsável por adicionar uma camada extra de complexidade às madeiras. Nesse processo que dura dois anos, o excesso de taninos indesejados acaba indo para o vinho, garantindo um whisky mais complexo e elegante, no qual os sabores dos óleos essenciais do carvalho ficam mais evidentes.',
      image: '/images/macallan4.jpg',
      reverse: true
    },
    {
      title: 'Cor natural',
      description: 'The Macallan não utiliza corantes artificiais em nenhum de seus whiskies. Utilizam as madeiras mais nobres que existem para fazer os barris, o que confere a The Macallan cor natural, além de aromas e sabores únicos.',
      image: '/images/macallan5.jpg',
    },
  ];

  return (
    <>
      <BrandContainer brand={'white'} noBottom>
        <FormContent noBottom>
          <BrandTitle center brand='themacallan'>Os 5 pilares de The Macallan</BrandTitle>


          {
            pillarsInfo.map((pillar, index) => (
              <MacallanCard key={index} {...pillar} />
            ))
          }
        </FormContent>
      </BrandContainer>
    </>
  )
}

