import React, { useContext, useState } from 'react';
import { CheckboxContainer, Container, Disclaimer, FormRow, InputContainer, Label, Overlay, Subtitle, Title } from './styled';
import Check from 'components/Form/Check';
import Select from 'components/Form/Select';
import { ButtonContainer, Icon } from 'ui/styled';
import { dateOptions } from 'utils/options';
import Button from 'components/Form/Button';
import useWindowSize from 'utils/useWindowSize';
import { CoreContext } from 'context/CoreContext';
import Input from 'components/Form/Input';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { toast } from 'react-toastify';
import { isEmail } from 'utils/validators';
import { exposeStrapiError, getHubSpotUTK, numerize } from 'utils';
import { Create } from 'services/agrees';
import { CreateForm, getUserIp } from 'services/form';
import useAnalytics from 'hooks/useAnalytics';

export default function AgeVerificationForm({ handleAgeActive }) {

  const { setTrackEvent, setIdentity, setPathPageView, setContentType } = useTrackingCode({ hubspotId: "46178129" });

  const windowSize = useWindowSize();
  const [rememberMe, setRememberMe] = useState(false);

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [loading, setLoading] = useState(false)

  const [form, setForm] = useState({})
  const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
  const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

  const hutk = getHubSpotUTK();
  const { agree, setAgree } = useContext(CoreContext)
  const { track } = useAnalytics();
  function isAge18OrOlder(day, month, year) {
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();

    const age = today.getFullYear() - birthDate.getFullYear();

    // Check if the birthday has occurred this year
    const hasBirthdayPassed =
      today.getMonth() > birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

    return age > 18 || (age === 18 && hasBirthdayPassed);
  }

  const valid = (verbose = false) => {

    if (!formValue('name') || !formValue('name').length) {
      if (verbose) { toast.error('Informe o nome para assinar'); }
      return false;
    }

    if (!formValue('email') || !formValue('email').length) {
      if (verbose) { toast.error('Informe o email para assinar'); }
      return false;
    }

    if (!isEmail(formValue('email'))) {
      if (verbose) { toast.error('Informe um email válido para assinar'); }
      return false;
    }

    return true
  }
  const saveHubSpot = async () => {

    const ipAddress = await getUserIp()
    const submittedAt = Date.now()

    const data = {
      submittedAt: submittedAt.toString(),
      fields: [
        // { objectTypeId: "0-1", name: "email", value: formValue('email') },
        // { objectTypeId: "0-1", name: "firstname", value: formValue('name') },
        { objectTypeId: "0-1", name: "birthdate", value: `${year}-${numerize(month)}-${numerize(day)}` },
        { objectTypeId: "0-1", name: "source", value: "age-verification" },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
        hutk,
        ipAddress: ipAddress,
      },
    }

    // await CreateForm(data)

  }

  const save = async () => {

    if (isAge18OrOlder(day, month, year) && day && month && year) {
      // if (!valid(true)) { return; }
      if (!loading) {
        const userData = {
          email: "prime@email.com",
          customProperties: {
            // Nome: formValue('name'),
            birthdate: `${year}-${numerize(month)}-${numerize(day)}`
          }
        }
        setLoading(true)
        setPathPageView("/");
        setIdentity(userData?.customProperties);
        setContentType("landing-page");
        setTrackEvent({
          eventId: "agree",
          birthdate: `${year}-${numerize(month)}-${numerize(day)}`
        })

        setPathPageView("/");
        // setIdentity(form?.email);
        setContentType("landing-page");
        setTrackEvent({ eventId: "agree", birthdate: `${year}-${numerize(month)}-${numerize(day)}` })

        track('age_verification', { birthdate: `${year}-${numerize(month)}-${numerize(day)}` })

        const result = await Create({ data: { birthdate: `${year}-${numerize(month)}-${numerize(day)}` } })

        if (!exposeStrapiError(result)) {
          toast.success("Permitido com sucesso")
        }
        setLoading(false)
      }

      await saveHubSpot()

      if (!!rememberMe) {
        setAgree({ day, month, year, rememberMe })
      }
    } else {
      window.location.href = "https://www.responsibility.org/prevent-underage-drinking/college-students-and-alcohol/alcohol101plus/"
    }
    handleAgeActive()
  }

  return agree ? null : (
    <>
      <Overlay active>
        <Container>
          <Icon src="/icons/side-logo.svg" alt="Logo" />
          <Title>Bem Vindo ao site da Prime Spirits Club</Title>
          <Subtitle>Para visitar o site, você deve ter mais de 18 anos.<br />Beba com responsabilidade.</Subtitle>
          {/* <InputContainer>
            <Input width={'100%'} label='Nome' placeholder="Nome" value={formValue('name')} onChange={e => changeForm(e.target.value, 'name')} noClear noRadius />
            <Input width={'100%'} label='E-mail' placeholder="E-mail" value={formValue('email')} onChange={e => changeForm(e.target.value, 'email')} noClear noRadius />
          </InputContainer> */}
          <FormRow>
            <Select options={dateOptions.days} placeholder="Dia"
              label
              lightgrey
              value={day} onChange={setDay}
              width={windowSize.width < 600 ? '300px' : '182.67px'}
              noRadius
            />
            <Select width={windowSize.width < 600 ? '300px' : '182.67px'}
              value={month} onChange={setMonth}
              lightgrey options={dateOptions.months} placeholder="Mês" label noRadius />
            <Select width={windowSize.width < 600 ? '300px' : '182.67px'} lightgrey noRadius
              value={year} onChange={setYear}
              options={dateOptions.years} placeholder="Ano" label />
          </FormRow>

          <CheckboxContainer>
            <Check
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
              label='Lembre-se de mim, por favor'
            />
          </CheckboxContainer>
          <Disclaimer>
            Suntory Global Spirits incentiva o consumo responsável. O álcool deve ser consumido com moderação. Ao entrar neste site, você concorda com nossos Termos e Condições, Política de Privacidade e Política de Cookies.
          </Disclaimer>
          <ButtonContainer>
            <Button width={'290px'} onClick={() => save()} primary>Entrar no site</Button>
          </ButtonContainer>
        </Container>
      </Overlay>
    </>
  );
};