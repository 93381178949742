import React from 'react'
import { ContentIcon, FooterContainer, FooterContent, FormContent, InfoContent, InfoFooter, InfoFooterContent, InfoText, LogoContent, SocialContent, Text, TextContainer, Title } from './styled'
import { Content, Icon } from 'ui/styled'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Footer({ noInfo, noFooter, light }) {

  const history = useHistory();
  const navigate = to => history.push(`/${to}`);


  const footerOptions = [
    {
      title: "Nossas marcas",
      options: [
        { text: "Jim Beam", onClick: () => navigate('marcas/Jim-Beam') },
        { text: "Maker’s Mark", onClick: () => navigate('marcas/Makers-Mark') },
        { text: "The House Of Suntory", onClick: () => navigate('marcas/The-House-Of-Suntory') },
        { text: "Laphroaig", onClick: () => navigate('marcas/Laphroaig') },
        { text: "Roku Gin", onClick: () => navigate('marcas/Roku-Gin') },
      ]
    },
    {
      title: "Compre por categoria",
      options: [
        { text: "Whisky", onClick: () => navigate('produtos?category=1') },
        { text: "Gin", onClick: () => navigate('produtos?category=2') },
        { text: "Vodka", onClick: () => navigate('produtos?category=4') },
      ]
    },
    {
      title: "Compre por país",
      options: [
        { text: "Estados Unidos", onClick: () => navigate('produtos?departments=5&option=8') },
        { text: "Japão", onClick: () => navigate('produtos?departments=5&option=9') },
        { text: "Escócia", onClick: () => navigate('produtos?departments=5&option=10') },
      ]
    },

  ];
  const footerOptions2 = [

    {
      title: "Formas de pagamento",
      options: [],
      social: [
        { icon: '/icons/payment1.svg' },
        { icon: '/icons/payment2.svg' },
        { icon: '/icons/payment3.svg' },
        { icon: '/icons/payment4.svg' },
        { icon: '/icons/payment5.svg' },
        { icon: '/icons/payment6.svg' },
        { icon: '/icons/payment7.svg' },
      ]
    },
    {
      title: "Tecnologia",
      social: [
        { icon: '/images/VTEX_BIG.D.png', width: 100 },
        { icon: '/images/NUVEM-p-500.png', width: 100 },

      ]
    },
    {
      title: "Segurança",
      social: [
        { icon: '/images/le-logo-standard-white.png', width: 100 },
        { icon: '/images/vtex-pci-200-bw.png', onClick: () => window.open("https://secure.vtex.com/?an=winecom", "new"), width: 100 },

      ]
    },
  ];


  return (
    <>
      {noFooter ? null : <FooterContent>
        <FormContent>
          <InfoContent>

            <LogoContent>
              <Icon src="/icons/footer-logo.svg" alt="logo" onClick={() => window.scrollTo(0, 0)} />
              <Text>Somos uma plataforma que provê educação, vendas e experiências no universo de destilados premium.
              </Text>
            </LogoContent>

            <Content>
              <Text onClick={() => navigate("about")}>Sobre nós</Text>
              <Text onClick={() => navigate("terms")}>Política de Privacidade</Text>
            </Content>

            <ContentIcon flex>
              <Icon src={`/icons/instagram.svg`} onClick={() => window.open("https://www.instagram.com/primespiritsclub/", "new")} />
              <Text>Siga o Prime Spirits Club no Instagram</Text>
            </ContentIcon>
          </InfoContent>
          <InfoContent>
            {
              footerOptions?.map((option, index) => (
                <InfoContent key={index}>
                  <Content>
                    <Title>{option?.title}</Title>
                    {
                      option?.options.map((opt, index) => (
                        <Text key={index} onClick={opt.onClick}>{opt.text}</Text>
                      ))
                    }
                  </Content>
                </InfoContent>
              ))
            }
          </InfoContent>

          <InfoContent>
            {
              footerOptions2?.map((option, index) => (
                <InfoContent key={index}>
                  <Title>{option?.title}</Title>
                  {option?.options?.map((opt, index) => (
                    <Text key={index} onClick={opt.onClick}>{opt.text}</Text>
                  ))
                  }
                  <SocialContent key={index}>
                    {
                      option?.social && option?.social?.map((social, index) => (
                        <Icon src={social.icon} alt={social.icon} onClick={social.onClick} width={social.width} />
                      ))
                    }
                  </SocialContent>
                </InfoContent>
              ))
            }

          </InfoContent>

        </FormContent>
      </FooterContent >}
      {
        noInfo ? null : <InfoFooter light={light}>
          <InfoFooterContent>
            <InfoText light={light} marginTop>© Prime Spirits Club 2025.</InfoText>
          </InfoFooterContent>
        </InfoFooter>
      }
    </>
  )
}