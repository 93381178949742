import React from 'react'
import { HistoryContainer, Image, ImageSeparator, Subtitle, TitleContainer } from './styled'
import { BrandTitle, FormSpace } from 'ui/styled'


export default function MacallanCard({ title, description, image, reverse }) {
  return (<>
    <FormSpace large />
    <HistoryContainer reverse={reverse}>
      <TitleContainer noSpace>
        <BrandTitle brand={'themacallan'} max>{title}</BrandTitle>
        <ImageSeparator src={'/images/separador-macallan.png'} alt="" />
        <Subtitle>
          {description}
        </Subtitle>
      </TitleContainer>
      <Image src={image} alt="" />
    </HistoryContainer>
  </>
  )
}
