import styled from "styled-components";

export const PillarsImageContainer = styled.div.attrs({
})`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  max-width: calc(100% / 3 - 16px);
  @media (max-width: 991px) {
    min-width: 100%;
    max-width: 100%;
  }
  min-height: 600px;
`;

export const PillarsTitle = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 34px;
  font-weight: 400;
  text-align: left;
  color: ${p => p.theme.palette.colors.white};
  padding: 0 16px;
`;

export const PillarsDescription = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: ${p => p.theme.palette.colors.white};
  max-width: 200px;
  text-align: center;
  font-style: italic;

`;