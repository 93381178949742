import React, { useContext } from 'react'
import { ProductListContent, FormContent, ProductContent, TitleContent } from './styled'
import ProductCard from 'components/ProductCard'
import { Icon, Title } from 'ui/styled';
import Button from 'components/Form/Button';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { CoreContext } from 'context/CoreContext';

export default function ProductList({ title, products }) {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const location = useLocation();
  const { departments } = useContext(CoreContext)

  const extractSubItem = () => {
    const brand = location.pathname?.split('/')?.filter(f => f)?.pop()?.replace(/-/g, ' ');

    const brandName = brand?.toLowerCase() === 'makers mark' ? "maker's mark" : brand;

    const subItem = departments?.[1]?.options?.find(d => d?.title?.toLowerCase() === brandName?.toLowerCase())?.id;

    return subItem;

  }
  const subItemId = extractSubItem();


  return !products?.length ? null : (
    <>
      <ProductListContent>
        <FormContent>
          <TitleContent>
            <Title>{title}</Title>
            <Button info noRadius nospace width={'129px'} onClick={() => subItemId ? navigate(`produtos?departments=6&option=${subItemId}`) : navigate('produtos')}>
              Ver todos
            </Button>
          </TitleContent>
          <ProductContent>
            {
              products.map((drink, index) => (
                <ProductCard key={index} {...drink} />
              ))
            }
          </ProductContent>
        </FormContent>
      </ProductListContent>
    </>
  )
}
