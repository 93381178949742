import React from 'react'
import { BrandContainer, BrandTitle, FormContent, FormSpace } from 'ui/styled'
import { BrandImag, FourWCardContainer, FourWTitleContainer, SamuelsBackground, SamuelsCardContainer, SamuelsFamilyImage, Subtitle, TitleContainer } from './styled'
import SamuelsCard from 'components/Cards/Samuels';
import FourWCard from 'components/Cards/Fourw';


export default function Roku({ brand, productDetails }) {
  const samuelsInfo = [
    {
      title: 'Destilado com 6 botânicos japoneses',
      image: '/images/ingredientes-roku-2.png'
    },
  ];

  return (
    <>
      <BrandContainer brand={brand}>
        <FormContent>
          <TitleContainer>
            <BrandTitle brand={brand}>Destilado com 6 botânicos japoneses</BrandTitle>
            <BrandImag src={'/images/ingredientes-roku-2.png'} alt="Roku" />
          </TitleContainer>

        </FormContent>
      </BrandContainer>
      <FormContent>
        <TitleContainer>
          <BrandTitle brand={brand}>6 botânicos japoneses, colhidos no auge de cada estação</BrandTitle>
          <BrandImag src={'/images/botanicos-japoneses.png'} alt="Roku" />
        </TitleContainer>
      </FormContent>
      <FormContent>
        <TitleContainer>
          <BrandTitle brand={brand}>8 botânicos para o autêntico sabor de gin</BrandTitle>
          <BrandImag src={'/images/botanicos-normais.png'} alt="Roku" />
        </TitleContainer>
      </FormContent>
    </>
  )
}

