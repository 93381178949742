import styled from "styled-components";

export const FormContent = styled.div.attrs({
})` 
    ${props => props.big ? 'max-width: 295px' : ''};
    height: 640px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
 
`;
export const ImgContent = styled.div.attrs({
})` 
  background-color: ${props => props.theme.palette.colors.blue100};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  padding: 17px 0px 18px 0px;
  min-height: 330px;
  max-height: 330px;
  @media (max-width: 600px) {
    padding: 8px;
  }
;
`;

export const Content = styled.div.attrs({
})` 
  background-color: ${props => props.theme.palette.colors.white};
  padding: 0px 16px 24px 16px;
  min-height: 310px;
  max-height: 310px;
  width: ${props => props.big ? '' : '235.2px'};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

`;
export const TitleProduct = styled.div.attrs({
})` 
  font-family: DM Serif Display;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  margin-top:${props => props.noSpace ? '0' : '16px'} ;
`;

export const PriceContent = styled.div.attrs({
})` 
  margin-top: 16px;
  border-top: 1px solid ${props => props.theme.palette.gray.gray100};
  border-bottom: 1px solid ${props => props.theme.palette.gray.gray100};
`;

export const Price = styled.div.attrs({
})` 
  display: flex;
  margin-top: 8px;
  margin-bottom: 12px;
`;
export const OriginalPrice = styled.div.attrs({
})` 
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;  
  text-align: left;
  text-decoration-line: line-through;
  color: ${props => props.theme.palette.gray.gray300};
`;
export const DiscountedPrice = styled.div.attrs({
})` 
  font-family: MetronicPro;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
`;
export const PaymentDetails = styled.div.attrs({
})` 
  styleName: XXS-12px/Regular;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
  flex-wrap: wrap;
`;

export const Discount = styled.div.attrs({
})` 
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  width: 48px;
  height: 48px;
  top: 16px;
  left: 8px;
  background-color: ${props => props.theme.palette.colors.black};
  color: ${props => props.theme.palette.red.contrastText};
  text-transform: uppercase;
`;
export const DiscountContent = styled.div.attrs({
})` 
  display: flex;
  gap: 6px;
  margin-top: 8px;
`;
export const PaymentConditions = styled.div.attrs({
})` 
  font-family: MetronicPro;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  margin-bottom: 8px;
`;

